<template>
  <div>
    <div class="about container">
      <div>
        <h1>Welcome</h1>
        <p>
          I am a computer science student at the University of Applied Sciences
          Darmstadt.
          <br />
          This is my website where i just post my mostly unfinished projects
        </p>
        <div id="githubDiv">
          <i class="fab fa-github"></i>
          <a href="https://github.com/Beniox">My Github </a>
        </div>
        <!-- <router-link to="Contact" tag="Button">Contact me</router-link> -->
        <button
          @click="$router.push('Contact')"
          type="button"
          class="btn btn-secondary"
        >
          Contact me
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style scoped>
.about {
  display: flex;
  justify-content: center;
  /* margin: 20px; */
  margin-top: 10px;
}

.about div {
  background-color: white;
  /* height: 100%; */
  /* width: 50%; */
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  border-radius: 20px;
  padding: 20px;
}
.fa-github {
  font-size: 32px;
  margin-right: 10px;
}
#githubDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
